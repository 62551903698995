import './styles.css';

import PartySocket from 'partysocket';
import React, { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import type { GameAction, GameState } from './shared';

declare const PARTYKIT_HOST: string;

export interface PartyStateProps {
  readonly host: string;
  readonly room: string;
}

export interface PartyStateContext<TState, TAction> {
  readonly state: TState | null;
  readonly dispatch: (action: TAction) => void;
}

export function usePartyState<TState, TAction>({ room, host }: PartyStateProps): PartyStateContext<TState, TAction> {
  const [state, setState] = React.useState<TState | null>(null);

  useEffect(() => {
    const conn = new PartySocket({
      host,
      room,
    });

    conn.addEventListener('message', event => {
      setState(JSON.parse(event.data as string) as TState);
    });

    conn.addEventListener('open', () => {
      // TODO
    });

    conn.addEventListener('close', () => {
      // TODO
    });

    conn.addEventListener('error', () => {
      // TODO
    });

    return () => {
      conn.close();
    };
  }, [host, room]);

  return { state, dispatch: () => {} };
}

const App: React.FC<{ readonly party: PartyStateProps }> = ({ party }) => {
  const { state, dispatch } = usePartyState<GameState, GameAction>(party);
  return <div>{state != null ? Object.keys(state.players).length : 'waiting'}</div>;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.querySelector('#root')!);
root.render(
  <StrictMode>
    <App party={{ host: PARTYKIT_HOST, room: 'my-second-room' }} />
  </StrictMode>,
);
